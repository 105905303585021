import React, { useState } from "react"
import styled from "@emotion/styled"
import InstagramLogo from "../assets/svg/instagramLogo"
import LinkedinLogo from "../assets/svg/linkedinLogo"
import TwitterLogo from "../assets/svg/twitterLogo"
import FacebookLogo from "../assets/svg/facebookLogo"

const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
`

const Social = styled("a")`
  ${props => (props.align === "right" ? "margin-left:2rem" : "margin-right:2rem")}
`
const socialMedia = [
  {
    HoverValueOnIcon: "Instagram",
    SocialMediaLink: "https://www.instagram.com/piktorlabs",
    socialIcon: InstagramLogo,
  },
  {
    HoverValueOnIcon: "LinkedIn",
    SocialMediaLink: "https://www.linkedin.com/company/piktorlabs",
    socialIcon: LinkedinLogo,
  },
  {
    HoverValueOnIcon: "Twitter",
    SocialMediaLink: "https://twitter.com/piktorlabs",
    socialIcon: TwitterLogo,
  },
  {
    HoverValueOnIcon: "Facebook",
    SocialMediaLink: "https://www.facebook.com/piktorlabs",
    socialIcon: FacebookLogo,
  },
]

const SocialBanner = props => {
  const [bgColor, setbgColor] = useState(props.themeColor)
  const [indexValue, setIndex] = useState("")

  const onMouseOver = index => {
    setbgColor(props.hoverColor ? props.hoverColor : props.themeColor)
    setIndex(index)
  }

  const onHoverLeave = () => {
    setbgColor(props.themeColor)
    setIndex("")
  }

  return (
    <SocialWrapper css={props.style ? props.style : ""}>
      {socialMedia.map((item, index) => {
        return (
          <Social
            align={props.align}
            key={index}
            onMouseEnter={() => onMouseOver(index)}
            onMouseLeave={() => onHoverLeave()}
            title={item.HoverValueOnIcon}
            href={item.SocialMediaLink}
            target="_blank"
          >
            <item.socialIcon
              bgColor={indexValue === index ? bgColor : props.themeColor}
              width={props.width}
            ></item.socialIcon>
          </Social>
        )
      })}
    </SocialWrapper>
  )
}

export default SocialBanner
