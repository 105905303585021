const NavItems = [
  { label: "home", link: "/" },
  { label: "products", link: "/" },
  { label: "about", link: "/" },
  // { label: "work", link:"/work"},
  { label: "careers", link: "/" },
  { label: "contact", link: "/" },
]

export default NavItems
