/** @jsx jsx */

import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { css, jsx } from "@emotion/react"
import styled from "@emotion/styled"
import { NewMediaBreakpoints } from "../styles/newstyles"
import PiktorLogoDark from "../assets/svg/piktorLogoDark"
import PiktorLogoLight from "../assets/svg/piktorLogoLight"
import SideBar from "./sideBar"
import BurgerMenu from "../assets/svg/burgerMenuIcon"
import LinkType from "./linkType"
import NavItems from "./constant/NavItem"
import _ from "underscore"
import { keyFrameHeaderBorder } from "../styles/keyframes"
const { small, smallAndMedium, ipadPro } = NewMediaBreakpoints

const HeaderContainer = styled("header")`
  background-color: transparent;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5.125em;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: top 0.3s ease-in-out, background-color 0.6s ease-in-out;
  font-size: ${props => (props.isFluid ? "1.15vw" : "100%")};
  @media ${smallAndMedium} {
    font-size: 100%;
  }
  @media ${ipadPro} {
    font-size: 100%;
  }
  &.hidden {
    top: -5.125em;
  }
`

const Transition = styled.div`
  /* Removing the header container height */
`

const headerWrapper = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`

const tabsWrapper = css`
  width: 50%;
  margin-top: 3px;
  display: flex;
  justify-content: flex-end;
  @media ${small} {
    display: none;
  }
`

const tabsContent = css`
  font-family: OpenSans-Light;
  transition: 0.5s ease-in-out;
  font-size: 1em;
  margin-left: 2em;
  &:hover {
    color: #5451FF;
  }
  @media ${ipadPro} {
    font-size: 1.2em;
    font-weight: 600;
  }
`

const menuContainer = css`
  width: 50%;
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
`

const hideMenu = css`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: translate(100%, 0);
  transition: ease 300ms;
`

const showMenu = css`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: translate(0, 0);
  transition: ease 300ms;
`

const NavLink = styled(Link)`
  & > div {
    position: relative;
  }
  &.current-page {
    color:#5451FF;
  }
  & > div::before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -3px;
    left: 0;
  }
  &.current-page > div {
    font-family: OpenSans-Regular;
    &::before {
      background: #3552f2;
      animation: ${keyFrameHeaderBorder} 500ms ease-out 1.5s;
      animation-fill-mode: forwards;
    }
  }
`

const LogoWrapper = styled("div")`
  width: 7.5em;
  height: 2em;
  z-index: 22;
  ${props => (props.appendStyle ? props.appendStyle : "")}
`

const Header = props => {
  const [activeMenu, setMenu] = useState(false)
  const [showHeader, setHeaderVisibility] = useState(true)
  const [lastScrollPosition, setLastScrollPosition] = useState(0)
  const [headerBackground, setHeaderBackground] = useState(false)
  const [hideHeader,setHideHeader] = useState(false)
  const [bgColor] = useState(() => {
    if (props.theme === "dark") {
      return "#000000"
    } else if (props.theme === "light") {
      return "#ffffff"
    } else if (props.theme === "dark-hybrid") {
      return "#3e669a"
    } else {
      return "#f3f3f3"
    }
  })
  const [textColor] = useState(() => {
    if (props.theme === "dark" || props.theme === "dark-hybrid") {
      return "#ffffff"
    } else if (props.theme === "light") {
      return "#000000"
    } else {
      return "#000000"
    }
  })
  const delta = 100

  useEffect(() => {
    const throttledScrollFunction = _.throttle(handleScroll, 250)
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", throttledScrollFunction)
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", throttledScrollFunction)
      }
    }
  })

  const closeMenu = data => {
    openMenu(data)
  }

  const openMenu = data => {
    setMenu(data)
    disableOverflow(data)
  }

  const disableOverflow = data => {
    const overlay = document.getElementById("___gatsby")
    if (data === true) {
      overlay.style.overflow = "hidden"
    } else {
      overlay.style.overflow = ""
    }
  }
  const hasScrolled = () => {
    // Scroll down increatses window.scrollY's value
    const currentScrollPosition = window.scrollY
    if (currentScrollPosition === 0) {
      setHeaderBackground(false)
    }
    if (currentScrollPosition < 251) {
      setHideHeader(false)
    }
    if (currentScrollPosition > 250) {
      setHideHeader(true)
    }
    /** Scrolls less than delta then don't update */
    if (Math.abs(currentScrollPosition - lastScrollPosition) <= delta) {
      return
    }
    const scrollDown =
      currentScrollPosition - lastScrollPosition > 0 ? true : false
    const updatedShowHeader = !scrollDown // Do not show header when user scrolls down
    if (showHeader !== updatedShowHeader) {
      setHeaderVisibility(updatedShowHeader)

      if (updatedShowHeader && headerBackground !== true) {
        setHeaderBackground(true)
      }
    }
    setLastScrollPosition(currentScrollPosition)
  }

  const handleScroll = () => {
    hasScrolled()
  }

  return (
    <Transition>
      <HeaderContainer
        className={showHeader === false ? "hidden" : ""}
        theme={props.theme}
        isFluid={props.isFluid}
        css={css`
          color: ${textColor};
        `}
      >
        <nav
          css={css`
            display: flex;
            width: 100%;
            max-width: 94vw;
            justify-content: space-between;
            @media ${smallAndMedium} {
              max-width: 95%;
            }
            @media ${ipadPro} {
              max-width: 90%;
            }
            @media ${small} {
              max-width: 90%;
            }
          `}
        >
          <div css={headerWrapper}>
            <LogoWrapper
              appendStyle={
                props.config && props.config.logoStyle
                  ? props.config.logoStyle
                  : ""
              }
              css={css`opacity:${hideHeader?"0":"1"}`}
            >
              <LinkType path="/">
                {props && props.theme && props.theme === "light" && !activeMenu? (
                  <PiktorLogoDark />
                ) :!activeMenu ? (
                  <PiktorLogoLight />
                ):""}
              </LinkType>
            </LogoWrapper>
          </div>

          {!showMenu && (
            <div css={tabsWrapper}>
              {NavItems.map((item, i) => (
                <NavLink key={i} to={item.link} activeClassName="current-page">
                  <div css={tabsContent}>{item.label}</div>
                </NavLink>
              ))}
            </div>
          )}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events*/}
          <div css={menuContainer} onClick={() => openMenu(true)}>
            <BurgerMenu theme={props.theme} />
          </div>
        </nav>
        {/* <HeaderOverlay menuState={activeMenu} /> */}
        <div css={activeMenu ? showMenu : hideMenu}>
          <SideBar closeMenu={closeMenu} NavItems={NavItems} />
        </div>
      </HeaderContainer>
    </Transition>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
