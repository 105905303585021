/*
 * @Author: gaurav
 * @Date:   2020-06-09 20:15:40
 * @Last Modified by: gaurav
 * @Last Modified time: 2020-07-09 06:35:43
 */
import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled from "@emotion/styled"
import { mediaBreakpoints } from "../styles/styles"

const { small, ipad, ipadPro, medium } = mediaBreakpoints

const ProductImageBackgroundImage = props => {
  const height = props.height ? props.height : "82"
  const ImageBackground = styled(BackgroundImage)`
    background-size: cover;
    height: 100%;
    width: 100%;
    position: relative;
    height: ${height};
    @media ${small} {
      height: ${props.mobileHeight ? props.mobileHeight : "100vh"};
      min-height: ${props.mobileHeight ? props.mobileHeight : "100vh"};
    }
  `

  let sources = [
    props.fluid,
    {
      ...props.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  if (props.mobileFluid)
    sources = [
      props.mobileFluid,
      {
        ...props.fluid,
        media: `(min-width: 768px)`,
      },
    ]

  console.log("source", sources)
  return (
    <ImageBackground mobileHeight={props.mobileHeight} fluid={sources} alt="img-piktorlabs-header">
      {props.children}
    </ImageBackground>
  )
}

export default ProductImageBackgroundImage
