/**
 * This component will provide a an external link or internal link wrapper to the component based on the props.
 * props: {
 *  isExternalLink: false (Default to internal link) - Use true (Boolean , e.g, isExternal={true}) for external link
 *  path: Redirection path,
 *  styles: Styles to be applied to the wrapper anchor "<a>" tag. Styles should be passed as
 *  template string. e.g, <LinkType styles={`margin: 0; padding: 0;`}></LinkType>
 *  onClick: CallBack. e.g, <LinkType onClick={(e) => e.stopPropagation()}></LinkType>
 * }
 */

import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

const LinkType = props => {
  return (
    <>
      {props.isExternalLink ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={props.path}
          css={css`
            ${props.styles}
          `}
          onClick={props.onClick}
        >
          {props.children}
        </a>
      ) : (
        <Link
          to={props.path}
          css={css`
            ${props.styles}
          `}
          onClick={props.onClick}
        >
          {props.children}
        </Link>
      )}
    </>
  )
}

export default LinkType
