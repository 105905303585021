import React from "react";

function Iconfacebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width?props.width:"20"}
      height={props.height?props.height:props.width?props.width:"20"}
      viewBox="0 0 20 20"
    >
      <path
        fill={props.bgColor}
        d="M18.848.045c.607 0 1.098.492 1.098 1.099v17.704c0 .607-.491 1.098-1.098 1.098h-5.071V12.24h2.587l.387-3.004h-2.974V7.32c0-.87.241-1.462 1.488-1.462l1.59-.001V3.169a21.26 21.26 0 00-2.317-.118c-2.293 0-3.863 1.4-3.863 3.97v2.215H8.082v3.004h2.593v7.706H1.144a1.098 1.098 0 01-1.099-1.098V1.144C.045.537.537.045 1.144.045z"
      ></path>
    </svg>
  );
}

export default Iconfacebook;