import { keyframes } from "@emotion/react"

export const keyFrameHeaderBorder = keyframes`
0% {
  right: 100%;
}
100% {
  right: 0;
}
`

export const keyFrameLoadTile = keyframes`
  0% {
    opacity: 0;
    transform: translateY(200px);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`
export const keyFrameLoadTextTile = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`

export const keyFrameScaleOut = keyframes`
0% {
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
}
100% {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
`

export const keyFrameScaleDown = keyframes`
0% {
  transform: translateY(-100px);
}

100% {
  transform: translateY(0);
}
`

export const keyFramewidthScale = keyframes`
0% {
  opacity: 0;
  transform:scaleX(0);
  visibility: hidden;
}
100% {
  opacity: 1;
  transform:scaleX(1);
  visibility: visible;
}
`

export const keyFrameText = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`
