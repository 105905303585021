/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useRef } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import { BottomContactUsPage } from "./bottomContactUsPage"
import styled from "@emotion/styled"
import { GlobalStyles, mediaBreakpoints } from "../styles/styles"
import { css } from "@emotion/react"
import { useTrackVisibilityObserver } from "./../hooks/use-trackVisibilityObserver"
import "../styles/overflowScroll.css"
const { smallAndMedium, ipadPro } = mediaBreakpoints

const Container = styled(GlobalStyles.AnimatedLayout)`
  opacity: ${props => (props.inView ? 1 : 0)};
  margin: auto;
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @media ${smallAndMedium} {
    font-size: 100%;
    width: 100%;
  }
  @media ${ipadPro} {
    font-size: 100%;
  }
`
const Layout = props => {
  // props.children.props.children[0].props.pathname
  const layOutRef = useRef(null)

  const [inView] = useTrackVisibilityObserver(layOutRef, {
    threshold: 0,
  })

  return (
    <Container
      theme={props.theme}
      duration={props.duration}
      inView={inView}
      count={1}
      ref={layOutRef}
    >
      <Header theme={props.theme} config={props.config} isFluid={true} />
      <div>
        <main
          css={css`
            width: 100%;
            font-size: ${props.isFluidPage ? "1.15vw" : "100%"};
            @media ${smallAndMedium} {
              font-size: 100%;
            }
            @media ${ipadPro} {
              font-size: 100%;
            }
          `}
        >
          {props.children}
        </main>
      </div>
      {props.bottomContactPage && (
        <BottomContactUsPage
          email={props.email}
          title={props.title}
          punchLine={props.punchLine || "Get in touch"}
          sentence={props.sentence || ""}
          backgroundColor={props.backgroundColor || "#5451ff"}
          buttonText="CONTACT US"
        />
      )}
      <Footer isFluid={true} />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
