import React from "react"
import { css } from "@emotion/react"
import MenuLightIcon from "../images/menu-light.png"
import styled from "@emotion/styled"
import hamburgerIcon from "../svg/hamburger.svg"
import hamburgerIconBlack from "../svg/hamburgerDark.svg"
import { mediaBreakpoints } from "../../styles/styles"
const Menu = css`
  height: 45px;
  width:45px;
  &:hover {
    cursor: "pointer";
  }
  @media ${mediaBreakpoints.small} {
    height: 45px;
    width: 45px;
  }
`

function BurgerMenu(props) {
  let theme = props.theme || "dark"
  return <img css={Menu} src={theme === 'dark' ? hamburgerIconBlack : hamburgerIcon} />
}

export default BurgerMenu
