import React from "react"
import Layout from "../components/layout"
import { GlobalStyles, mediaBreakpoints } from "../styles/styles"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const pageWrapper = css`
  width: 100%;
  height: calc(100vh);
  display: flex;
  justify-content: center;
`

const pageContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
`

const pageTitle = css`
  display: flex;
  @media ${mediaBreakpoints.small} {
  }
`
const S = styled.span`
  font-size: 12rem;
  padding: 0 1rem;
  font-weight: bold;
  @media ${mediaBreakpoints.small} {
    font-size: 8rem;
    padding: 0 5px;
  }
`
const bounce = css`
animation: bounce 3s;  
animation-iteration-count: infinite;
@keyframes bounce {
  0% {
    transform: translateY(-60px);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translateY(0);
    animation-timing-function: ease-in;
  }
  26% {
    transform: translateY(0)  scaleY(0.9) scaleX(1.15);
  }
  50% {
    transform: translateY(-60px) ;
    animation-timing-function: ease-in;    
  }
  75% {
    transform: translateY(0) ;
    animation-timing-function: ease-in;
  }
  76% {
    transform: translateY(0) scaleY(0.9) scaleX(1.15);
  }
  100% {
    transform: translateY(-60px) ;
    animation-timing-function: ease-in;
  }
};
@media ${mediaBreakpoints.small} {
  @keyframes bounce {
    0% {
      transform: translateY(-35px);
      animation-timing-function: ease-in;
    }
    25% {
      transform: translateY(0);
      animation-timing-function: ease-in;
    }
    26% {
      transform: translateY(0)  scaleY(0.9) scaleX(1.15);
    }
    50% {
      transform: translateY(-35px) ;
      animation-timing-function: ease-in;    
    }
    75% {
      transform: translateY(0) ;
      animation-timing-function: ease-in;
    }
    76% {
      transform: translateY(0) scaleY(0.9) scaleX(1.15);
    }
    100% {
      transform: translateY(-35px) ;
      animation-timing-function: ease-in;
    }
`

const P = styled.p`
  font-family: OpenSans-Light;
  color: #000000;
  opacity: 0.4;
  letter-spacing: 0.67px;
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0;
`

const content = css`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const errorText = css`
  margin: 0 0 2rem 0;
  @media ${mediaBreakpoints.small} {
    margin: 0 0 3rem 0;
  }
`
const dot = css`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #3552f2;
  position: absolute;
  left: 51%;
  top: 8rem;
  display: none;
  @media ${mediaBreakpoints.small} {
    width: 4rem;
    height: 4rem;
    left: 48%;
    top: 7rem;
  }
`
const buttonWrapper = css`
  margin-top: 2rem;
`

const NotFoundPage = () => (
  <Layout theme="light">
    <GlobalStyles.StyledSection>
      <GlobalStyles.SectionWrapper
        css={css`
          padding: 0;
        `}
      >
        <div css={pageWrapper}>
          <div css={pageContent}>
            <div css={dot}></div>
            <div css={content}>
              <div css={pageTitle}>
                <div>
                  <S>4</S>
                </div>
                <div css={bounce}>
                  <S
                    css={css`
                      color: #5451FF;
                    `}
                  >
                    O
                  </S>
                </div>
                <div>
                  <S>4</S>
                </div>
              </div>
              <div css={errorText}>
                <P>
                  {" "}
                  Oops! This page doesn’t exist. Looking to explore how we build
                  future-focused products? Head to our home page.
                </P>
              </div>
              <div css={buttonWrapper}>
                <GlobalStyles.Button to="/">back to home</GlobalStyles.Button>
              </div>
            </div>
          </div>
        </div>
      </GlobalStyles.SectionWrapper>
    </GlobalStyles.StyledSection>
  </Layout>
)
export default NotFoundPage
