import { graphql, useStaticQuery } from "gatsby";

const useEmailContact = () => {
  const emails = useStaticQuery(graphql`
    query {
      allWordpressWpEmailcontacts {
        edges {
          node {
            acf {
              EmailAddress: emailaddress
              Purpose: purpose
            }
          }
        }
      }
    }
  `);

  return emails.allWordpressWpEmailcontacts.edges.map((email) => ({
    purpose: email.node.acf.Purpose,
    emailAddress: email.node.acf.EmailAddress,
  }));
};

export default useEmailContact;
