import React from "react";

function IconInstagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width?props.width:"20"}
      height={props.height?props.height:props.width?props.width:"20"}
      viewBox="0 0 20 20"
    >
      <path
        fill={props.bgColor ? props.bgColor:'#313131'}
        fillRule="evenodd"
        d="M11.024.007c1.8.003 2.18.018 3.095.06 1.064.048 1.79.217 2.426.464a4.9 4.9 0 011.77 1.153 4.9 4.9 0 011.154 1.77c.247.637.416 1.363.465 2.427.043.952.057 1.326.06 3.327v1.59c-.003 2.001-.017 2.375-.06 3.327-.049 1.064-.218 1.79-.465 2.426a4.9 4.9 0 01-1.153 1.771 4.9 4.9 0 01-1.77 1.153c-.637.247-1.363.416-2.427.465-.952.043-1.326.057-3.327.06h-1.59c-2.001-.003-2.375-.017-3.327-.06-1.064-.049-1.79-.218-2.426-.465a4.9 4.9 0 01-1.771-1.153 4.9 4.9 0 01-1.153-1.77c-.247-.636-.416-1.363-.465-2.427C.018 13.21.004 12.83 0 11.03V8.976c.004-1.8.018-2.18.06-3.095.049-1.064.218-1.79.465-2.426a4.9 4.9 0 011.153-1.77A4.9 4.9 0 013.448.53C4.085.284 4.812.115 5.876.066 6.79.025 7.17.01 8.97.007zm.014 1.801H8.955c-1.737.003-2.1.017-2.998.058-.974.044-1.504.207-1.856.344-.467.181-.8.398-1.15.748a3.09 3.09 0 00-.747 1.15c-.137.352-.3.88-.344 1.856-.041.898-.055 1.26-.058 2.998v2.083c.003 1.737.017 2.1.058 2.998.044.974.207 1.504.344 1.856.181.467.398.8.748 1.15.35.35.682.566 1.149.747.352.137.882.3 1.856.344.976.045 1.32.057 3.484.059h1.112c2.164-.002 2.508-.014 3.483-.059.975-.044 1.504-.207 1.857-.344a3.1 3.1 0 001.15-.748c.349-.35.566-.682.747-1.149.137-.352.3-.882.344-1.856.045-.976.057-1.32.058-3.484V9.447c-.001-2.164-.013-2.507-.058-3.483-.044-.975-.207-1.504-.344-1.857a3.097 3.097 0 00-.748-1.15 3.097 3.097 0 00-1.15-.747c-.352-.137-.88-.3-1.856-.344-.898-.041-1.26-.055-2.998-.058zM10 4.848a5.151 5.151 0 110 10.303 5.151 5.151 0 010-10.303zm0 1.808a3.344 3.344 0 100 6.688 3.344 3.344 0 000-6.688zm5.455-3.323a1.212 1.212 0 110 2.425 1.212 1.212 0 010-2.425z"
      ></path>
    </svg>
  );
}

export default IconInstagram;