
import React from "react";

function IconClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
    >
      <g
        fill="#cccccc"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-316 -26) translate(316 26)"
      >
        <path d="M-3 11H27V14H-3z" transform="rotate(45 12 12.5)"></path>
        <path
          d="M-3 11H27V14H-3z"
          transform="scale(-1 1) rotate(45 0 -16.47)"
        ></path>
      </g>
    </svg>
  );
}

export default IconClose;