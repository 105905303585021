
import { graphql, useStaticQuery } from "gatsby";

const useHome = () => {
  const menuPage = useStaticQuery(graphql`
        query {
            allWordpressAcfMenupagedata {
                edges {
                  node {
                    acf {
                      firstsectionimage {
                        localFile {
                            childImageSharp {
                              photo: fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                      }
                      bgimage {
                        localFile {
                            childImageSharp {
                              photo: fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                      }
                      firstsectiontext
                      firstsectiontitle
                      secondsectionimage {
                        localFile {
                            childImageSharp {
                              photo: fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                      }
                      secondsectiontext
                      secondsectiontitle
                      theme
                    }
                  }
                }
              }
          }
  `);
  
  let menu = menuPage.allWordpressAcfMenupagedata.edges[0];
  let dataObj = {
    firstsectiontext: menu.node.acf.firstsectiontext,
    firstsectiontitle: menu.node.acf.firstsectiontitle,
    secondsectiontext: menu.node.acf.secondsectiontext,
    secondsectiontitle: menu.node.acf.secondsectiontitle,
    theme: menu.node.acf.theme,
   firstsectionimage:menu?.node?.acf?.firstsectionimage?.localFile?.childImageSharp?.photo || null,
   bgimage:menu?.node?.acf?.bgimage?.localFile?.childImageSharp?.photo || null,
   secondsectionimage:menu?.node?.acf?.secondsectionimage?.localFile?.childImageSharp?.photo || null,
  }
 return dataObj;

};

export default useHome;
