/*
 * @Author: gaurav
 * @Date:   2020-06-04 16:48:39
 * @Last Modified by:   gaurav
 * @Last Modified time: 2020-06-04 17:58:09
 */
import { graphql, useStaticQuery } from "gatsby";

const useLocations = () => {
  const locations = useStaticQuery(graphql`
    query MyQuery {
      allWordpressAcfOfficelocations(sort: { fields: id, order: DESC }) {
        edges {
          location: node {
            acf {
              LocationTitle: locationtitle
              OfficeAddress: officeaddress
              Coordinates: coordinates
            }
          }
        }
      }
    }
  `);

  return locations.allWordpressAcfOfficelocations.edges
    .map(({ location }) => ({
      LocationTitle: location.acf.LocationTitle,
      OfficeAddress: location.acf.OfficeAddress,
      Coordinates: location.acf.Coordinates,
    }))
    .reverse();
};

export default useLocations;
