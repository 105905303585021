import React from "react";

function IconTwitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width?props.width:"20"}
      height={props.height?props.height:props.width?props.width:"17"}
      viewBox="0 0 20 17"
    >
      <path
        fill={props.bgColor}
        d="M6.288 16.538c7.544 0 11.672-6.256 11.672-11.672 0-.176 0-.352-.008-.528A8.357 8.357 0 0020 2.21a8.314 8.314 0 01-2.36.648A4.127 4.127 0 0019.448.586a8.16 8.16 0 01-2.608.992A4.095 4.095 0 0013.848.282a4.107 4.107 0 00-4.104 4.104c0 .32.04.632.104.936a11.649 11.649 0 01-8.456-4.288 4.113 4.113 0 001.272 5.48 4.15 4.15 0 01-1.856-.512v.056a4.11 4.11 0 003.288 4.024 4 4 0 01-1.08.144c-.264 0-.52-.024-.768-.072a4.102 4.102 0 003.832 2.848 8.228 8.228 0 01-5.096 1.76c-.328 0-.656-.016-.976-.056a11.669 11.669 0 006.28 1.832"
      ></path>
    </svg>
  );
}

export default IconTwitter;