import { graphql, useStaticQuery } from "gatsby"

const useImages = () => {
  const data = useStaticQuery(graphql`
    query {
      ImageAppleStore: imageSharp(
        fluid: { originalName: { eq: "ImageAppleStore.png" } }
      ) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      ImageGooglePlay: imageSharp(
        fluid: { originalName: { eq: "ImageGooglePlay.png" } }
      ) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      contactSea: imageSharp(
        fluid: { originalName: { eq: "img_contact_SA.jpg" } }
      ) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      contactBglr: imageSharp(
        fluid: { originalName: { eq: "img_contact_BGLR.jpg" } }
      ) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      spaceneedleDark: imageSharp(
        fluid: { originalName: { eq: "space.jpg" } }
      ) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }

      partenWithUsImg: imageSharp(
        fluid: { originalName: { eq: "partenWithUsImg.png" } }
      ) {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }

      closeImg: imageSharp(fluid: { originalName: { eq: "close.png" } }) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }

      question: imageSharp(fluid: { originalName: { eq: "question.png" } }) {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }

      emailIcon: imageSharp(fluid: { originalName: { eq: "emailIcon.png" } }) {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
      adpImage1: imageSharp(fluid: { originalName: { eq: "Adp1.png" } }) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      adpImage2: imageSharp(fluid: { originalName: { eq: "Adp2.png" } }) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      adpImage3: imageSharp(fluid: { originalName: { eq: "Adp4.png" } }) {
        fluid(maxWidth: 6000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      adpMob1: imageSharp(fluid: { originalName: { eq: "adp1mob.png" } }) {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      adpMob2: imageSharp(fluid: { originalName: { eq: "adp2mob.png" } }) {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      adpMob3: imageSharp(fluid: { originalName: { eq: "adp3mob.png" } }) {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  return data
}

export default useImages
